var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagecontainer"},[(_vm.showForgotPasswordForm)?_c('a-card',{staticClass:"login-form "},[_c('div',{staticClass:"my2"},[_c('h5',[_vm._v("Forgot Password")]),_vm._v(" "),_c('span',[_vm._v("Enter your email address below, and we’ll send you an email with a\n        new password")])]),_vm._v(" "),_c('a-form-item',[_c('a-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.resetEmail),callback:function ($$v) {_vm.resetEmail=$$v},expression:"resetEmail"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('transition',{attrs:{"name":"visible"}},[(_vm.message)?_c('div',[_c('span',{staticStyle:{"color":"#f5222d"}},[_vm._v(" "+_vm._s(_vm.message))])]):_vm._e(),_vm._v(" "),(_vm.successMessage)?_c('div',[_c('span',{staticStyle:{"color":"#52c41a"}},[_vm._v(" "+_vm._s(_vm.successMessage))])]):_vm._e(),_vm._v(" "),(_vm.loading == true)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_c('span',[_vm._v(" Sending Email")])],1):_vm._e()]),_vm._v(" "),_c('a-form-item',[_c('a-button',{staticClass:"btBig login-form-button",attrs:{"type":"primary","html-type":"submit","block":""},on:{"click":_vm.handleForgotPassword}},[_vm._v("\n        Send\n      ")])],1),_vm._v(" "),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('span',{staticClass:"custom-link",on:{"click":_vm.handleLogInClick}},[_vm._v("\n        Back to Log in\n      ")])])],1):_c('a-card',{staticClass:"login-form "},[_c('div',{staticClass:"my2"},[_c('h5',[_vm._v("Login")])]),_vm._v(" "),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!'
                },
                { required: true, message: 'Please input your Email!' }
              ]
            }
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: 'The input is not valid E-mail!'\n                },\n                { required: true, message: 'Please input your Email!' }\n              ]\n            }\n          ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Please input your Password!' }
              ]
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Please input your Password!' }\n              ]\n            }\n          ]"}],attrs:{"type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remember',
            {
              valuePropName: 'checked',
              initialValue: true
            }
          ]),expression:"[\n            'remember',\n            {\n              valuePropName: 'checked',\n              initialValue: true\n            }\n          ]"}]},[_vm._v("\n          Remember me\n        ")]),_vm._v(" "),_c('span',{staticClass:"custom-link forgot-password",attrs:{"type":"flex","justify":"end"},on:{"click":function($event){_vm.showForgotPasswordForm = true}}},[_vm._v("\n          Forgot password?\n        ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('a-button',{staticClass:"btBig login-form-button",attrs:{"type":"primary","html-type":"submit","block":""}},[_vm._v("\n          SignIn\n        ")]),_vm._v(" "),_c('div',[_c('span',[_vm._v("Don't have an account? ")]),_vm._v(" "),_c('span',{staticClass:"custom-link",on:{"click":_vm.handleSignUpClick}},[_vm._v("\n            Sign Up For Free\n          ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }