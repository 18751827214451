//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Component, Vue } from "nuxt-property-decorator";
@Component({
  middleware: ["login"]
})
export default class Login extends Vue {
  showForgotPasswordForm = false;
  resetEmail = "";
  message = "";
  successMessage = "";
  loading = false;

  created() {
    this.form = this.$form.createForm(this, {
      name: "normal_login"
    });
  }
  handleSubmit(e) {
    const that = this;
    e.preventDefault();
    that.form.validateFields((err, values) => {
      // console.log("handleSubmit", values);
      if (!err) {
        // console.log("Received values of form: ", values);
        that.$auth
          .loginWith(that.$auth.options.defaultStrategy, {
            data: {
              email: values.email,
              password: values.password
            }
          })
          .then(res => {
            if (res && res.status == 200 && res.data && res.data.token) {
              that.$message.success("Login Success !");
              if (values.rememberMe) {
                that.$auth.$storage.setCookie(
                  `_remember.${that.$auth.options.defaultStrategy}`,
                  that.$auth.strategy.token.get(),
                  {
                    maxAge: 2147483647
                  }
                );
              }
              that.$router.push("/");
            } else {
              that.$message.error("Unable to Login !");
            }
          })
          .catch((err, res) => {
            console.log("login error", err);
            // console.log("res1err", err.response);
            // that.$message.error("Login Failed !");
            if (
                err.response &&
                err.response.data &&
                err.response.data.message
            ) {
                that.$message.error(err.response.data.message);

            } else {
                that.$message.error("login failed !");
            }
          });
      }
    });
  }

  handleForgotPassword(e) {
    if (!this.resetEmail) {
      this.handleSetMessage(
        "Please enter your email address!",
        this,
        "message"
      );
      return;
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resetEmail)) {
      this.loading = true;
      this.$axios
        .post(`${process.env.authURL}/user/forgotPassword`, {
          app: `${process.env.APP_TYPE ? process.env.APP_TYPE : "aistudio"}`,
          email: `${this.resetEmail}`
        })
        .then(res => {
          // console.log("forgot password", res);
          this.loading = false;
          if (res.status === 200) {
            if (res?.data?.code === 200) {
              this.handleSetMessage(
                "Check you email for new password!",
                this,
                "successMessage"
              );
              setTimeout(() => {
                this.showForgotPasswordForm = false;
              }, 3000);
            } else if (res?.data?.code === 404) {
              this.handleSetMessage("User not found!", this, "message");
            }
          }
        })
        .catch(err => {
          this.loading = false;
          this.handleSetMessage("Oops! Something went wrong!", this, "message");
          console.log(err);
        });
    } else {
      this.handleSetMessage("Not a valid email address!", this, "message");
      return;
    }
  }

  handleSetMessage = (msg, that, key) => {
    that[key] = msg;
    setTimeout(() => {
      that[key] = "";
    }, 3000);
  };

  handleSignUpClick(e) {
    this.$router.push({ path: "/auth/signup" });
  }

  handleLogInClick() {
    this.showForgotPasswordForm = false;
  }
}
